import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UnregisteredUserState } from './state';

export const selectUnregisteredUserState =
  createFeatureSelector<UnregisteredUserState>('unregisteredUser');

export const selectUnregisteredUserByUid = (uid: string) => createSelector(
  selectUnregisteredUserState,
  (state: UnregisteredUserState) => state.users[uid],
);

export const selectUnregisteredUserByEmail = (email: string) => createSelector(
  selectUnregisteredUserState,
  (state: UnregisteredUserState) =>
    Object.values(state.users).find((user) => user.email === email),
);
