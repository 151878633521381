import { createAction, props, union } from '@ngrx/store';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';

export enum UnregisteredUserActionTypes {
  UnregisteredUserLoadOne = '[UnregisteredUser] Load One',
  UnregisteredUserLoadOneSuccess = '[UnregisteredUser] Load One Success',
  UnregisteredUserLoadOneError = '[UnregisteredUser] Load One Error',

  UnregisteredUserLoadOneByEmail = '[UnregisteredUser] Load One By Email',
  UnregisteredUserLoadOneByEmailSuccess = '[UnregisteredUser] Load One By Email Success',
  UnregisteredUserLoadOneByEmailError = '[UnregisteredUser] Load One By Email Error',

  UnregisteredUserLoadOneByUid = '[UnregisteredUser] Load One By Uid',
  UnregisteredUserLoadOneByUidSuccess = '[UnregisteredUser] Load One By Uid Success',
  UnregisteredUserLoadOneByUidError = '[UnregisteredUser] Load One By Uid Error',

  UnregisteredUserDeleteOne = '[UnregisteredUser] Delete One',
  UnregisteredUserDeleteOneSuccess = '[UnregisteredUser] Delete One Success',
  UnregisteredUserDeleteOneError = '[UnregisteredUser] Delete One Error',

  UnregisteredUserSignUp = '[UnregisteredUser] Sign Up',
  UnregisteredUserSignUpSuccess = '[UnregisteredUser] Sign Up Success',
  UnregisteredUserSignUpError = '[UnregisteredUser] Sign Up Error',

  UnregisteredUserCreateOne = '[UnregisteredUser] Create One',
  UnregisteredUserCreateOneSuccess = '[UnregisteredUser] Create One Success',
  UnregisteredUserCreateOneError = '[UnregisteredUser] Create One Error',
};

export const unregisteredUserLoadOne = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOne,
  props<{ uid: string }>(),
);
export const unregisteredUserLoadOneSuccess = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOneSuccess,
  props<{ user: GthUnregisteredUserModel }>(),
);
export const unregisteredUserLoadOneError = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOneError,
  props<{ error: unknown }>(),
);

export const unregisteredUserLoadOneByEmail = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOneByEmail,
  props<{ email: string }>(),
);
export const unregisteredUserLoadOneByEmailSuccess = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOneByEmailSuccess,
  props<{ user: GthUnregisteredUserModel }>(),
);
export const unregisteredUserLoadOneByEmailError = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOneByEmailError,
  props<{ error: unknown }>(),
);

export const unregisteredUserLoadOneByUid = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOneByUid,
  props<{ uid: string }>(),
);
export const unregisteredUserLoadOneByUidSuccess = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOneByUidSuccess,
  props<{ user: GthUnregisteredUserModel }>(),
);
export const unregisteredUserLoadOneByUidError = createAction(
  UnregisteredUserActionTypes.UnregisteredUserLoadOneByUidError,
  props<{ error: unknown }>(),
);

export const unregisteredUserDeleteOne = createAction(
  UnregisteredUserActionTypes.UnregisteredUserDeleteOne,
  props<{ uid: string }>(),
);
export const unregisteredUserDeleteOneSuccess = createAction(
  UnregisteredUserActionTypes.UnregisteredUserDeleteOneSuccess,
  props<{ uid: string }>(),
);
export const unregisteredUserDeleteOneError = createAction(
  UnregisteredUserActionTypes.UnregisteredUserDeleteOneError,
  props<{ error: unknown }>(),
);

export const unregisteredUserSignUp = createAction(
  UnregisteredUserActionTypes.UnregisteredUserSignUp,
  props<{ uid: string, newUid: string }>(),
);
export const unregisteredUserSignUpSuccess = createAction(
  UnregisteredUserActionTypes.UnregisteredUserSignUpSuccess,
  props<{ uid: string }>(),
);
export const unregisteredUserSignUpError = createAction(
  UnregisteredUserActionTypes.UnregisteredUserSignUpError,
  props<{ error: unknown }>(),
);

export const unregisteredUserCreateOne = createAction(
  UnregisteredUserActionTypes.UnregisteredUserCreateOne,
  props<{ user: GthUnregisteredUserModel }>(),
);
export const unregisteredUserCreateOneSuccess = createAction(
  UnregisteredUserActionTypes.UnregisteredUserCreateOneSuccess,
  props<{ user: GthUnregisteredUserModel }>(),
);
export const unregisteredUserCreateOneError = createAction(
  UnregisteredUserActionTypes.UnregisteredUserCreateOneError,
  props<{ error: unknown }>(),
);

export const allUnregisteredUsers = union({
  unregisteredUserLoadOne,
  unregisteredUserLoadOneSuccess,
  unregisteredUserLoadOneError,
  unregisteredUserDeleteOne,
  unregisteredUserDeleteOneSuccess,
  unregisteredUserDeleteOneError,
  unregisteredUserLoadOneByEmail,
  unregisteredUserLoadOneByEmailSuccess,
  unregisteredUserLoadOneByEmailError,
  unregisteredUserSignUp,
  unregisteredUserSignUpSuccess,
  unregisteredUserSignUpError,
  unregisteredUserCreateOne,
  unregisteredUserCreateOneSuccess,
  unregisteredUserCreateOneError,
});

export type UnregisteredUserActionsUnion = typeof allUnregisteredUsers;
