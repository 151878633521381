<div class="login-form">
  <h2 class="header">
    Let's create your account
  </h2>
  <div class="existing-account">
    <p>Already have an account?</p>

    @if (signInRoute) {
      <a mat-button color="primary" [routerLink]="signInRoute">Sign In</a>
    } @else {
      <a mat-button color="primary" (click)="onLoginButtonClick()">Sign In</a>
    }
  </div>
  <form class="form-content" [formGroup]="formGroup">
    <!-- Name -->
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput required minlength="2" maxlength="100" type="text" class="fullName"
        formControlName="fullName" #name />
      <mat-hint matTextSuffix align="end">
        {{name.value.length}}/100
      </mat-hint>
      <mat-error>
        Please use a minimum of 2 characters
      </mat-error>
      <br>
    </mat-form-field>

    <!-- Email -->
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput required maxlength="100" type="email" class="email" formControlName="email"
        matTooltip="Please use format: name@example.com" matTooltipPosition="above"
        [matTooltipDisabled]="formGroup.get('email')?.valid" />
      <mat-error>
        Please enter a valid email address
      </mat-error>
    </mat-form-field>

    <!-- Password -->
    <label for="passwordStrengthCtrl">Password Strength</label>
    <mat-progress-bar id="passwordStrengthCtrl" class="progress-bar" mode="determinate"
      value="{{ passwordStrength * 100 }}"></mat-progress-bar>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput required minlength="8" maxlength="100" class="password" type="password"
        formControlName="password" (keyup)="onPasswordChange()" [type]="hidePassword ? 'password' : 'text'" />
      <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword">
        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error>
        Password requires 8 characters with a mix of letters, numbers, and symbols
      </mat-error>
    </mat-form-field>

    <!-- Confirm Password -->
    <mat-form-field appearance="outline">
      <mat-label>
        Confirm password
      </mat-label>
      <input matInput required class="confirm-password" type="password" il8n="Input for confirm password"
        formControlName="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'" />
      <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
        [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmPassword">
        <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <mat-error>
        Passwords do not match
      </mat-error>
    </mat-form-field>

    <mat-checkbox class="remember-me-checkbox" formControlName="termsAndConditions">By creating an account, you agree to
      our
      <a role="button" (click)="openPoliciesDialog(privacyTabIndex)" class="policy-link">Privacy Policy</a>,
      <a role="button" (click)="openPoliciesDialog(termsConditionsTabIndex)" class="policy-link">Terms and
        Conditions</a>, and
      <a role="button" (click)="openPoliciesDialog(notificationsTabIndex)" class="policy-link">Cookie
        Policy</a>.</mat-checkbox>
    <div class="submit-button-container" matTooltip="Please complete all fields and policies."
      matTooltipPosition="below" [matTooltipDisabled]="formGroup.valid">
      <button class="submit-button" type="button" color="primary" (click)="onSignUpButtonClick()"
        [disabled]="attempted || formGroup.invalid || (passwordMismatch$ | async)"
        mat-raised-button>
        @if (loading) {
          <mat-spinner [diameter]="36" />
        } @else {
          Create Account
        }
      </button>
    </div>

    <p class="or-container">
      <span class="or-line"></span><span class="or-text">or</span><span class="or-line"></span>
    </p>
  </form>

  <div class="social-buttons fx-column">
    <gth-google-button class="social-btn" (socialClick)="onGoogleSignUpButtonClick()"
      text="Sign up with Google"></gth-google-button>
  </div>
</div>