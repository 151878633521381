<app-custom-stepper (skip)="onSkip()" ngSkipHydration>
  @for (step of steps; track step.id) {
  <cdk-step>
    <picture>
      <img alt="" [src]="step.image" />
    </picture>

    <div class="text-container">
      <h1 class="title">{{ step.title }}</h1>

      <p>{{ step.description }}</p>
    </div>
  </cdk-step>
  }
</app-custom-stepper>
