import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { GthSocialButtonsModule } from '../social-buttons/social-buttons.module';
import { GthCookiePolicyComponent } from './policies/cookies/cookie-policy.component';
import { GthPolicyDialogComponent } from './policies/policy-dialog.component';
import { GthPrivacyPolicyComponent } from './policies/privacy/privacy-policy.component';
import { GthTermsConditionsComponent } from './policies/terms-conditions/terms-conditions.component';
import { GthSignUpFormComponent } from './sign-up-form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    MatProgressSpinner,
    GthSocialButtonsModule,
  ],
  exports: [GthSignUpFormComponent],
  declarations: [
    GthSignUpFormComponent,
    GthPolicyDialogComponent,
    GthPrivacyPolicyComponent,
    GthCookiePolicyComponent,
    GthTermsConditionsComponent,
  ],
})
export class GthSignUpFormModule { }
