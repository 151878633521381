@if (isFirstVisit() && !inDialog()) {
  <app-first-visit-stepper [class.inDialog]="inDialog()" (completed)="onCompleteStepper()" />
} @else {
  <div class="sign-up-form" [class.inDialog]="inDialog()">
    <a class="logo" [routerLink]="routes.Root">
      <img src="assets/logo-standing-fullcolor.webp" class="logo" />
    </a>
    <gth-sign-up-form 
      [signInRoute]="loginRoute()"
      [loading]="loading()"
      [email]="userInfo()?.email"
      [name]="userInfo()?.fullName"
      (signUp)="onSignUp($event)"
    ></gth-sign-up-form>
  </div>

  <input class="cpy-input" #urlInput [value]="currentUrl" readonly>
}