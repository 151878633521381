import { InjectionToken } from '@angular/core';

export interface GthEnvironment {
  root: string;
  appName: string;
  envName: string;
  teamsEnabled: boolean;
}

export const GTH_ENVIRONMENT = new InjectionToken<GthEnvironment>('GTH-ENVIRONMENT');
