<button mat-button color="accent" class="skip-btn" (click)="skip.emit()">Skip</button>

<div [ngTemplateOutlet]="selected ? selected.content : null" class="content"></div>

<div class="buttons-container">
  <button mat-icon-button class="nav-btn" [ngClass]="{'hidden': selectedIndex === 0}" cdkStepperPrevious>
    <mat-icon fontIcon="chevron_left" />
  </button>

  <div class="dots">
    <mat-icon *ngFor="let step of steps; let i=index"
              fontIcon="circle"
              (click)="selectStepByIndex(i)"
              [ngClass]="{'active': selectedIndex === i}" />
  </div>

  <button mat-icon-button class="nav-btn" (click)="onNextBtnClick()">
    <mat-icon fontIcon="chevron_right" />
  </button>
</div>
