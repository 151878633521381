import { createReducer, on } from '@ngrx/store';

import * as UnregUserActions from './actions';
import { UnregisteredUserState } from './state';

export const intialUnregisteredUserState: UnregisteredUserState = {
  users: {},
};

export const unregisteredUserReducer = createReducer(
  intialUnregisteredUserState,
  on(UnregUserActions.unregisteredUserLoadOneSuccess, (state, { user }) => {
    return {
      ...state,
      users: {
        ...state.users,
        [user.uid]: user,
      },
    };
  }),

  on(UnregUserActions.unregisteredUserLoadOneByEmailSuccess, (state, { user }) => {
    return {
      ...state,
      users: {
        ...state.users,
        [user.uid]: user,
      },
    };
  }),

  on(UnregUserActions.unregisteredUserDeleteOneSuccess, (state, { uid }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [uid]: _, ...users } = state.users;
    return {
      ...state,
      users,
    };
  }),
);
