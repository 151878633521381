import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgForOf } from '@angular/common';
import { Component, EventEmitter, forwardRef, Output } from '@angular/core';

import { CustomStepperComponent } from './custom-stepper/custom-stepper.component';

@Component({
  selector: 'app-first-visit-stepper',
  templateUrl: './first-visit-stepper.component.html',
  styleUrls: ['./first-visit-stepper.component.scss'],
  standalone: true,
  imports: [
    forwardRef(() => CustomStepperComponent),
    CustomStepperComponent,
    CdkStepperModule,
    NgForOf,
  ],
})
export class FirstVisitStepperComponent {
  @Output()
  completed = new EventEmitter();

  steps = [
    {
      id: 1,
      image: 'assets/basketball_dunk.webp',
      title: 'Discover your tribe effortlessly.',
      // eslint-disable-next-line max-len
      description: 'Find like-minded individuals who match your skill level, hobby, sport, and area.',
    },
    {
      id: 2,
      image: 'assets/esports.webp',
      title: 'Organize Like Never Before!',
      // eslint-disable-next-line max-len
      description: 'Plan events, organize teams, and find the right sub & participants seamlessly.',
    },
    {
      id: 3,
      image: 'assets/volleyball.webp',
      title: 'More Than an App. A Community.',
      // eslint-disable-next-line max-len
      description: 'Welcome to Gametime Hero — where connections spark, passions thrive. More play, less work. Play, thrive, make memories!',
    },
  ];

  onSkip() {
    this.completed.emit();
  }
}
