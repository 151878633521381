import { Injectable } from '@angular/core';
import { APP_STATE } from '@gth-legacy';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AutoLoadService {
  constructor(protected store: Store<APP_STATE>) {}

  /**
   * Automatically loads data from the database if it's not already
   * present in the NgRx store.
   *
   * @param {function(state: APP_STATE): T} selector - A selector
   * function to retrieve data from the store.
   * @param {any} loadAction - The NgRx action to dispatch if data
   * is not found in the store.
   * @return {Observable<T | null>} An Observable emitting either:
   *      - The loaded data (of type T) if it's found in the store
   *      or after being loaded from the DB.
   *      - `null` while data is being loaded from the DB.
   */
  public autoLoadIfNotFound<T>(
    selector: (state: APP_STATE) => T,
    loadAction: any,
  ): Observable<T | null> {
    return this.store.select(selector).pipe(
      switchMap((item) => {
        if (!item) {
          this.store.dispatch(loadAction);
        }
        return of(item);
      }),
      map((itemOrNull) => itemOrNull as T | null), // Ensures type safety
    );
  }
}
