import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgTemplateOutlet,
    NgClass,
    CdkStepperModule,
    NgIf,
    NgForOf,
  ],
  providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }],
})
export class CustomStepperComponent extends CdkStepper {
  @Output()
  skip = new EventEmitter();

  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }

  onNextBtnClick() {
    if (this.steps.length - 1 === this.selectedIndex) this.skip.emit();
    else this.next();
  }
}
